exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-a-member-js": () => import("./../../../src/pages/become-a-member.js" /* webpackChunkName: "component---src-pages-become-a-member-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-farm-bill-js": () => import("./../../../src/pages/farm-bill.js" /* webpackChunkName: "component---src-pages-farm-bill-js" */),
  "component---src-pages-federal-food-donation-liability-protections-js": () => import("./../../../src/pages/federal-food-donation-liability-protections.js" /* webpackChunkName: "component---src-pages-federal-food-donation-liability-protections-js" */),
  "component---src-pages-federal-js": () => import("./../../../src/pages/federal.js" /* webpackChunkName: "component---src-pages-federal-js" */),
  "component---src-pages-food-waste-action-plan-js": () => import("./../../../src/pages/food-waste-action-plan.js" /* webpackChunkName: "component---src-pages-food-waste-action-plan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-priority-legislation-js": () => import("./../../../src/pages/priority-legislation.js" /* webpackChunkName: "component---src-pages-priority-legislation-js" */),
  "component---src-pages-state-js": () => import("./../../../src/pages/state.js" /* webpackChunkName: "component---src-pages-state-js" */),
  "component---src-pages-state-toolkit-js": () => import("./../../../src/pages/state-toolkit.js" /* webpackChunkName: "component---src-pages-state-toolkit-js" */),
  "component---src-templates-action-js": () => import("./../../../src/templates/Action.js" /* webpackChunkName: "component---src-templates-action-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/NewsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-state-toolkit-section-js": () => import("./../../../src/templates/stateToolkitSection.js" /* webpackChunkName: "component---src-templates-state-toolkit-section-js" */)
}


import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  width: 100%;
  background-color: #095d89;
  text-align: center;
  padding: 10vh 0;
  color: #fff;
  a {
    color: #d0cfcf;
  }
  .contact {
    @media (max-width: 1024px) {
      width: 80vw;
      margin: 0 auto;
    }
  }
  .iconCredit {
    font-size: 10px;
  }
`;

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          homepage: allSanityHomepage {
            nodes {
              contactEmail
            }
          }
        }
      `}
      render={(data) => {
        const homepage = data.homepage.nodes[0];
        return (
          <FooterStyles>
            <div className="contact">
              {/* <p>
              To join the coalition, please complete <a href="https://docs.google.com/forms/d/19MO7ey-I1Xi6zQWLfQm6Uj7trAbxpFNdYWQFyNP1hZo/viewform?edit_requested=true">this interest form</a>. <br /> For inquiries or feedback on these recommendations, contact&nbsp;
                <a href={`mailto:${homepage.contactEmail}`}>
                  {homepage.contactEmail}
                </a>
              </p> */}
            </div>
            <a href="https://www.worldwildlife.org/privacy">Privacy Policy</a>
            <p>&copy; World Wildlife Fund {new Date().getFullYear()}</p>
            <div className="iconCredit">
              Favicon made by{' '}
              <a href="https://www.flaticon.com/authors/monkik" title="monkik">
                monkik
              </a>{' '}
              from{' '}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </FooterStyles>
        );
      }}
    />
  );
}

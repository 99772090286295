import React from 'react';
import { useState } from "react";
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';

const NavStyles = styled.nav`
  div {
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    padding: 1.5rem 2rem;
    background-color: #eaeaea;
    color: var(--darkPurple);
    z-index: 2;
    transition: top 500ms ease-in-out;
  }

  div > button {
    display: none;
  }

  &.scroll-up {
    top: 0;
  }

  &.scroll-down {
    top: -100%;
  }

  &.scroll-up,
  &:focus-within {
    top: 0;
  }

  ul {
    list-style: none;
  }

   ul {
    display: flex;
    gap: .5rem;
    list-style-type: none;
    min-width: 70vw;
    align-items: stretch;
    justify-content: center;
  }

   ul a, button {
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    transition: color 0.15s ease-in-out;
  }

  ul .is-active {
    font-weight: bold;
  }

  li {
    min-width: 135px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: .25em;
    box-sizing: border-box;
  }

  button {
    padding: 0.75em 1em;
    border-radius: 0;
    background-color: rgb(234, 234, 234);
    line-height: 1;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    line-height: 1.6875rem;
    transition: color 0.15s ease-in-out;
    color: #0f0f0f;
    font-size: 1.125rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  button * {
    pointer-events: none;
  }

  button:hover {
    cursor: pointer;
  }

  // .links {
  //   display: flex;
  //   align-items: center;
  //   margin: 0 -1em;
  //   flex-direction: row;
  //   justify-self: flex-end;
  // }


  .links a {
    display: inline-block;
    margin: 0 0.5rem;
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }

  .logo {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  a {
    color: #0f0f0f;
    text-decoration: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  .has-dropdown {
    position: relative;
  }

  .has-dropdown ul {
    display: none;
    position: absolute;
    z-index: 100;
    min-width: 260px;
    top: 90%;
    right: 0;
    bottom: auto;
    left: 0;
    padding: 1rem;
    border: 2px solid black;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    background-color: rgb(234, 234, 234);
    li {
      display: block;
      text-align: left;
    }
  }

  .has-dropdown > button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: .25rem .5rem;
    margin-top: -1px;
    border: none;
  }

  .has-dropdown > button::after {
    content: '';
    width: 0.85rem;
    height: 0.75em;
    margin-top: -0.25rem;
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    border-color: var(--action-color);
    transform: rotate(135deg);
  }

  .has-dropdown > button.show::after {
    margin-top: 0.25rem;
    transform: rotate(-45deg);
  }

  .has-dropdown > button.show ~ ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .menu-icon {
    font-size: 4em;
  }

  .close-icon {
    font-size: 2em;
  }

  @media (max-width: 1200px) {
    ul.links.menu li.menu-item:first-child {
      display: none;
    }
    ul.links.menu {
      min-width: auto;
    }
  }

  @media (max-width: 1000px) {
    div > button {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 100px;
      height: 100px;
      border: none;
      text-decoration: none;
    }
    div.shown > ul {
      display: flex;
    }
    div.hidden > ul{
      display: none;
    }
    ul {
      flex-direction: column;
      align-items: flex-start;
    }

    ul.dropdown-menu {
      left: 0;
      min-width: auto;
      width: 100%;

      li a {
        margin: 0;
      }
    }

    ul li {
      min-width: auto;
      text-align: left;
    }

    .links {
      flex-direction: column;

      a {
        margin-top: 0.5rem;
      }
    }
  }
  @media (max-width: 600px) {
    div.mobile-wrapper {
      display: block;

      ul.links-menu {
        padding-left: 1em;
      }
    }
  }
`;



//make a conditional wrapper to toggle mobile navigation
function MobileWrapper({ children, isToggled }) {
  return (
    <div className={[`mobile-wrapper ${isToggled ? "shown" : "hidden"}`]}>
      {children}
    </div>
  );
}

export default function Nav({ location }) {
  const path = location && location.pathname ? location.pathname : '';
  const [isToggled, toggle] = useState(false);

  
  // react useRef <nav> element
  const navRef = React.useRef(null);

  React.useEffect(() => {
    const mainNav = navRef.current;
    const mainMenu = mainNav.querySelector('ul')
    const dropdownMenus = [...mainNav.querySelectorAll('.has-dropdown button')]
  
    const isOutOfViewport = (element) => {
      const elementBounds = element.getBoundingClientRect()
      return elementBounds.right > (window.innerWidth || document.documentElement.clientWidth)
    }

    const openDropdownMenu = (dropdownMenu) => {
      const dropdownList = dropdownMenu.parentNode.querySelector('ul')
  
      dropdownMenu.classList.add('show')
      dropdownMenu.setAttribute('aria-expanded', 'true')
  
      if (isOutOfViewport(dropdownList)) {
        dropdownList.style.left = 'auto'
      }
    }
  
    const closeDropdownMenu = (dropdownMenu) => {
      dropdownMenu.classList.remove('show')
      dropdownMenu.setAttribute('aria-expanded', 'false')
    }
  
    const closeAllDropdownMenus = () => {
      for (let i = 0; i < dropdownMenus.length; i++) {
        closeDropdownMenu(dropdownMenus[i])
      }
    }
  
    const toggleDropdownMenu = (event) => {
      if (event.target.getAttribute('aria-expanded') === 'false') {
        closeAllDropdownMenus()
        openDropdownMenu(event.target)
      } else {
        closeDropdownMenu(event.target)
      }
    }

    mainMenu &&
    mainMenu.addEventListener('keydown', (event) => {
      const element = event.target
      const currentMenuItem = element.closest('li')
      const menuItems = [...mainMenu.querySelectorAll('.menu-item')]
      const currentDropdownMenu = element.closest('.has-dropdown button')
      const currentDropdownMenuItem = element.closest('.has-dropdown li')
      const currentIndex = menuItems.findIndex((item) => item === currentMenuItem)

      const key = event.key
      let targetItem

      if (key === 'ArrowRight') {
        if (menuItems.indexOf(currentMenuItem) === menuItems.length - 1) {
          targetItem = menuItems[0]
        } else {
          targetItem = menuItems[currentIndex + 1]
        }
      }

      if (key === 'ArrowLeft') {
        if (menuItems.indexOf(currentMenuItem) === 0) {
          targetItem = menuItems[menuItems.length - 1]
        } else {
          targetItem = menuItems[currentIndex - 1]
        }
      }

      if (key === 'Escape') {
        targetItem = menuItems[0]
      }

      if (currentDropdownMenu) {
        const firstDropdownItem = currentDropdownMenu.nextElementSibling.querySelector('li')

        if (key === 'ArrowDown') {
          event.preventDefault()
          openDropdownMenu(currentDropdownMenu)
          targetItem = firstDropdownItem
        }

        if (key === 'Escape') {
          closeDropdownMenu(currentDropdownMenu)
        }
      }

      if (currentDropdownMenuItem) {
        const currentDropdownList = currentDropdownMenuItem.parentNode
        const dropdownMenuItems = [...currentDropdownList.querySelectorAll('li')]
        const currentIndex = dropdownMenuItems.findIndex((item) => item === currentDropdownMenuItem)

        if (key === 'ArrowDown') {
          event.preventDefault()

          if (dropdownMenuItems.indexOf(currentDropdownMenuItem) === dropdownMenuItems.length - 1) {
            targetItem = dropdownMenuItems[0]
          } else {
            targetItem = dropdownMenuItems[currentIndex + 1]
          }
        }

        if (key === 'ArrowUp') {
          event.preventDefault()

          if (dropdownMenuItems.indexOf(currentDropdownMenuItem) === 0) {
            targetItem = dropdownMenuItems[dropdownMenuItems.length - 1]
          } else {
            targetItem = dropdownMenuItems[currentIndex - 1]
          }
        }

        if (key === 'Escape') {
          const currentDropdownMenu = (currentDropdownList).previousElementSibling
          targetItem = currentDropdownMenu.parentNode
          closeAllDropdownMenus()
        }
      }

      if (targetItem) {
        targetItem.querySelector('a, button, input').focus()
      }
    })

    dropdownMenus &&
    dropdownMenus.forEach((dropdownMenu) => {
      dropdownMenu.addEventListener('click', toggleDropdownMenu)
    });

    window.addEventListener('click', (event) => {
      const element = event.target
      if (!element.hasAttribute('aria-haspopup') && !element.classList.contains('submenu-item')) {
        closeAllDropdownMenus()
      }
    })
  
    return () => {
      console.log('unmounting');
      mainMenu.removeEventListener('keydown',null);
      dropdownMenus.forEach((dropdownMenu) => {
        dropdownMenu.removeEventListener('click', toggleDropdownMenu)
      });
      //dropdownMenu.removeEventListener('click',null);
      window.removeEventListener('click',null);
    }
  }, []);

  return (
    <NavStyles aria-label="Main navigation desktop" ref={navRef}>
      <MobileWrapper isToggled={isToggled}>
      <button aria-label="Open Mobile Navigation" className="absolute top-4 right-4 z-10 h-8 w-8 pr-4 [&:not(:focus-visible)]:focus:outline-none" onClick={() => toggle(!isToggled)}>
        {isToggled &&
          // <svg aria-hidden="true" className="w-3.5 overflow-visible stroke-snow" fill="none" strokeWidth="2" strokeLinecap="round" viewBox="0 0 8 8"><path d="M0 1H14M0 7H14M0 13H14" className="origin-center transition scale-90 opacity-0"></path><path d="M2 2L12 12M12 2L2 12" className="origin-center transition"></path></svg>
          <span class="close-icon">x</span>
        }
        {!isToggled &&
          // <svg aria-hidden="true" className="w-3.5 overflow-visible stroke-slate" fill="none" strokeWidth="2" strokeLinecap="round" viewBox="0 0 8 8"><path d="M0 1H14M0 7H14M0 13H14" className="origin-center transition"></path><path d="M2 2L12 12M12 2L2 12" className="origin-center transition scale-90 opacity-0"></path></svg>
          <span class="menu-icon">&#8801;</span>
        }
      </button>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="OneSource Logo" height="100" weight="100" />
          </Link>
        </div>
        <ul className="links menu">
          <li className='menu-item'>
            <Link to="/">Home</Link>
          </li>
          <li className='menu-item has-dropdown'>
          <button aria-haspopup="true" aria-expanded="false">About</button>
            <ul className="dropdown-menu">
              <li className="submenu-item">
                <Link to="/about">Overview</Link>
              </li>
              <li className="submenu-item">
                <Link to="/become-a-member">Become a Member</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item has-dropdown">
            <button aria-haspopup="true" aria-expanded="false">Federal</button>
            <ul className="dropdown-menu">
            <li className="submenu-item">
                <Link to="/federal">Overview</Link>
              </li>
              <li className="submenu-item">
                <Link to="/farm-bill">U.S. Farm Bill</Link>
              </li>
              <li className="submenu-item">
                <Link to="/food-waste-action-plan">Food Waste Action Plan</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item has-dropdown">
            <button aria-haspopup="true" aria-expanded="false">State</button>
            <ul className="dropdown-menu">
              <li className="submenu-item">
                <Link to="/state">Overview</Link>
              </li>
              <li className="submenu-item">
                <Link to="/state-toolkit">State Toolkit</Link>
              </li>
            </ul>
          </li>
          <li className='menu-item'>
            <Link to="/news">In The News</Link>
          </li>
          <li className='menu-item'>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </MobileWrapper>
    </NavStyles>
  );
}

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

const HeaderStyles = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: clamp(200px, 1000px, 40vh);
  background-color: #095d89;
  color: #fff;
  section {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
  }
  h1, p.hero-title {
    font-size: clamp(26px, 4vw, 42px);
    line-height: clamp(30px, 7vw, 70px);
    margin: 0 auto;
    width: 90%;
    padding: 45px 0;
    position: relative;
    text-transform: uppercase;
    /* font-family: OpenSans-ExtraBold; */
    font-weight: 800;
    position: relative;
    z-index: 2;

    /* &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &:before,
    &:after {
      content: '';
      width: 200px;
      height: 5px;
      background: #fff;
      position: absolute;
      left: 50%;
      margin-left: -100px;
    } */
  }
  .photo-copyright {
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: 2;
    font-size: 9px;
  }
  @media (max-width: 767px) {
    height: 30vh;
    line-height: auto;
  }
`;

const BackgroundImageStyles = styled.div`
  background-size: cover;
  background-position: top;
  display: block;
  height: clamp(200px, 1000px, 40vh);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  margin: 0;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 30vh;
  }
`;

export default function Header({location}) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          homepage: allSanityHomepage {
            nodes {
              headline
              image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              imageAttribution
            }
          }
        }
      `}
      render={(data) => {
        const homepage = data.homepage.nodes[0];
        return (
          <HeaderStyles>
            {homepage?.image ? (
              <BackgroundImageStyles>
                <GatsbyImage
                  image={homepage.image.asset.gatsbyImageData}
                  alt={homepage.imageAttribution}
                  style={{ position: 'auto' }}
                />
              </BackgroundImageStyles>
            ) : (
              ''
            )}
            <section className="center">
             {location.pathname === '/' || location.pathname === '' ? <h1>{parse(homepage.headline)}</h1> : <p class="hero-title">{parse(homepage.headline)}</p>}
            </section>
            <p className="photo-copyright">
              {parse(homepage.imageAttribution)}
            </p>
          </HeaderStyles>
        );
      }}
    />
  );
}

import { createGlobalStyle } from 'styled-components';

import font from '../assets/fonts/OpenSans-Regular.woff';
import font2 from '../assets/fonts/OpenSans-Regular.woff2';
import fontBold from '../assets/fonts/OpenSans-Bold.woff';
import fontBold2 from '../assets/fonts/OpenSans-Bold.woff2';
import fontExtraBold from '../assets/fonts/OpenSans-ExtraBold.woff';
import fontExtraBold2 from '../assets/fonts/OpenSans-ExtraBold.woff2';
import fontSemiBold from '../assets/fonts/OpenSans-Semibold.woff';
import fontSemiBold2 from '../assets/fonts/OpenSans-Semibold.woff2';
import fontItalic from '../assets/fonts/OpenSans-Italic.woff';
import fontItalic2 from '../assets/fonts/OpenSans-Italic.woff2';
import wwfFont from '../assets/fonts/wwf-webfont.woff';
import wwfFont2 from '../assets/fonts/wwf-webfont.woff2';

const Typography = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols+2&display=swap');
  /* @font-face {
    font-family: OpenSans-Regular;
    src:
      url(${font2}) format('woff2'),
      url(${font}) format('woff');
  }
  @font-face {
    font-family: OpenSans-Bold;
    src:
      url(${fontBold2}) format('woff2'),
      url(${fontBold}) format('woff');
  }
  @font-face {
    font-family: OpenSans-ExtraBold;
    src:
      url(${fontExtraBold2}) format('woff2'),
      url(${fontExtraBold}) format('woff');
  }
  @font-face {
    font-family: OpenSans-SemiBold;
    src:
      url(${fontSemiBold2}) format('woff2'),
      url(${fontSemiBold}) format('woff');
  }
  @font-face {
    font-family: OpenSans-Italic;
    src:
      url(${fontItalic2}) format('woff2'),
      url(${fontItalic}) format('woff');
  }
  */
  
  /* @font-face {
    font-family: "WWFRegular";
    src:
      url(${wwfFont2}) format('woff2'),
      url(${wwfFont}) format('woff');
  } */
  html {
    font-family: 'Open Sans', sans-serif;
    /* font-family: "Open Sans", OpenSans-Regular, sans-serif; */
    color: #000;
  }
  p, li {
    letter-spacing: 0.5px;
    line-height: 26px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
    /* color: var(--black); */
    /* text-decoration-color: var(--red); */
    /* Chrome renders this weird with this font, so we turn it off */
    /* text-decoration-skip-ink: none; */
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
